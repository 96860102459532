<!--  -->
<template>
  <div>日程预约</div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang='scss' scoped >
</style>
